* {
    scrollbar-width: thin;
    scrollbar-color: rgb(35, 35, 213);
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 24px;
    border: none;
}

td {
    border-bottom: none !important;
}

/* inspired by: https://css-tricks.com/responsive-data-tables/ */

.responsiveTable td .tdBefore {
    display: none;
}

@media screen and (max-width: 40em) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none; for accessibility)
    */

    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
        display: block;
    }

    .responsiveTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .responsiveTable tbody tr {
        padding: 0.1em;
    }

    .responsiveTable td.pivoted {
        /* Behave like a "row" */

        border: none !important;
        position: relative;
        padding-left: calc(35%) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .responsiveTable td .tdBefore {
        /* Now like a table header */
        position: absolute;
        display: block;

        /* Top/left values mimic padding */
        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    }
}
