.containerMap .circle-btn {
    cursor: pointer;
    &:hover {
        fill: black;
    }
}

.btn-menu {
    /* Frame 3 */

    padding-top: 4px;
    text-align: center;

    width: 130px;
    height: 40px;

    /* Gradien 1 */
    background: linear-gradient(224.72deg, #7fc0be 12.32%, #56689f 87.76%);
    box-shadow: 0px 0.245572px 0.245572px rgba(255, 0, 0, 0.25);
    border-radius: 30.6965px;

    border: 1.23px solid;
    border-image-source: linear-gradient(46.32deg, 'tranpsarent', #56689f 93.73%);

    /* Scoreboard */

    font-family: 'Chelsea Market';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 29px;
    /* identical to box height, or 224% */
    letter-spacing: 0.05em;

    color: #ffffff;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-scrollbar {
    scrollbar-width: none;
    scrollbar-color: #ffffff00 #00000000;
}

/* styles.css */
@media only screen and (orientation: portrait) {
    .landscape-only {
        display: none;
    }

    .rotate-device {
        display: block;
        text-align: center;
        margin-top: 50px;
        font-size: 20px;
        color: red; /* Puedes cambiar el estilo según tus necesidades */
    }
}

@media only screen and (orientation: landscape) {
    .landscape-only {
        display: block;
    }

    .rotate-device {
        display: none;
    }
}
